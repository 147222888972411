import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { useStore } from "@feat/common/store/store";

// Create an Axios instance with the base URL
const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "/api/",
});

// Add Authorization header to requests
client.interceptors.request.use((config) => {
  const { accessToken: token } = useStore.getState();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle token refresh
const refreshTokens = async (refreshToken: string) => {
  const response = await client.post("auth/refresh", { refreshToken });
  return response.data;
};

// Handle 401 errors
const handle401Error = (error: AxiosError & { config: AxiosRequestConfig }) => {
  const { refreshToken, login, logout } = useStore.getState();

  if (error.response?.status !== 401) {
    return Promise.reject(error);
  }

  console.log("401 error. URL: ", error.config.url);

  if (error.config.url?.includes("auth/refresh")) {
    return handleRefreshTokenError(error, logout);
  }

  if (error.config.url?.includes("auth/login")) {
    console.log("401 on login endpoint. Stored credentials are invalid.");
    return Promise.reject(error);
  }

  if (refreshToken) {
    return attemptTokenRefresh(error, refreshToken, login, logout);
  } else {
    console.log("No refresh token available. Logging out.");
    logout();
    return Promise.reject(error);
  }
};

// Handle refresh token error by attempting to use stored credentials
const handleRefreshTokenError = async (
  error: AxiosError & { config: AxiosRequestConfig },
  logout: () => void,
) => {
  console.log("401 on refresh token. Attempting to use stored credentials.");

  const storedCredentials = localStorage.getItem("credentials");
  if (!storedCredentials) {
    console.log("No stored credentials. Logging out.");
    logout();
    return Promise.reject(error);
  }

  const { username, password } = JSON.parse(storedCredentials);
  try {
    const loginResponse = await client.post("auth/login", {
      username,
      password,
    });
    const { access_token: jwtToken, refresh_token: newRefreshToken } =
      loginResponse.data;
    console.log("Login succeeded with stored credentials.");
    const { login } = useStore.getState();
    login(username, jwtToken, newRefreshToken);
    error.config.headers.Authorization = `Bearer ${jwtToken}`;
    return await client.request(error.config);
  } catch (loginError) {
    console.log("Login failed with stored credentials. Logging out.");
    logout();
    localStorage.removeItem("credentials");
    return await Promise.reject(loginError);
  }
};

// Attempt to refresh the access token
const attemptTokenRefresh = async (
  error: AxiosError & { config: AxiosRequestConfig },
  refreshToken: string,
  login: (username: string, jwtToken: string, refreshToken: string) => void,
  logout: () => void,
) => {
  console.log("Attempting to refresh access token...");

  try {
    const refreshResponse = await refreshTokens(refreshToken);
    const { access_token: jwtToken_1, refresh_token: newRefreshToken } =
      refreshResponse;
    console.log("Refresh succeeded. Retrying original request.");
    login("user", jwtToken_1, newRefreshToken);
    error.config.headers.Authorization = `Bearer ${jwtToken_1}`;
    return await client.request(error.config);
  } catch (refreshError) {
    console.log("Refresh failed. Attempting to login with stored credentials.");
    return await handleRefreshTokenError(error, logout);
  }
};

// Interceptor to handle 401 errors and attempt token refresh
client.interceptors.response.use(
  (response) => response,
  (error) => handle401Error(error),
);

export default client;
