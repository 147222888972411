import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "@store/store";
import { Ingress, Page } from "@comp/Page";
import { SectionHeader } from "@comp/SectionHeader";
import {
  Grid,
  List,
  ListItem,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SidebarItemData } from "@comp/SidebarItem";
import React from "react";
import { Bookable, ContentBlock } from "@api/types";
import CustomButton from "@comp/Button";
import Section from "@comp/Section";
import theme from "src/theme";
import MuiMarkdown from "mui-markdown";
import { slugify } from "markdown-to-jsx";
import { isFunction } from "@mui/x-data-grid/internals";
import EditButton from "@comp/EditButton";
import { AddCircleOutlineRounded } from "@mui/icons-material";

const CourseDetail = () => {
  const wide = useMediaQuery(theme.breakpoints.up("md"));

  // Admin stuff
  const {
    edit,
    patchCourse,
    updateCourseContent,
    insertCourseContent,
    removeCourseContent,
    moveCourseContent,
  } = useStore();
  const [editingTitle, setEditingTitle] = React.useState(false);
  const [newTitle, setNewTitle] = React.useState<string | undefined>(undefined);
  const [editingParticipants, setEditingParticipants] = React.useState(false);
  const [newParticipants, setNewParticipants] = React.useState<
    string | undefined
  >(undefined);
  const [editingDays, setEditingDays] = React.useState(false);
  const [newDays, setNewDays] = React.useState<string | undefined>(undefined);
  const [editingPrerequisites, setEditingPrerequisites] = React.useState(false);
  const [newPrerequisites, setNewPrerequisites] = React.useState<
    string | undefined
  >(undefined);
  const [editingPrice, setEditingPrice] = React.useState(false);
  const [newPrice, setNewPrice] = React.useState<number | undefined>(undefined);
  const [editingIngress, setEditingIngress] = React.useState(false);
  const [newIngress, setNewIngress] = React.useState<string | undefined>(
    undefined
  );
  const [editingContentAtIndex, setEditingContentAtIndex] = React.useState<
    number | null
  >(null);
  const [insertingContentAtIndex, setInsertingContentAtIndex] = React.useState<
    number | null
  >(null);
  const [newContent, setNewContent] = React.useState<ContentBlock | undefined>(
    undefined
  );

  const { course_slug } = useParams<{ course_slug: string }>();
  const { courses, fetchingCourses, bookables } = useStore();
  const course = courses.find((c) => c.slug === course_slug);

  /**
   * Edit title
   */
  const editTitle = () => {
    setNewTitle(course?.title);
    setEditingTitle(true);
  };

  const cancelEditingTitle = () => {
    setEditingTitle(false);
  };

  const saveNewTitle = () => {
    if (newTitle && course) {
      patchCourse({ title: newTitle }, course.id).then(() => {
        const newSlug = slugify(newTitle);
        window.history.pushState(
          {},
          "",
          `/courses/${course.category_slug}/${newSlug}`
        );
        setEditingTitle(false);
      });
    }
  };

  /**
   * Edit participants
   */
  const editParticipants = () => {
    setNewParticipants(course?.max_participants);
    setEditingParticipants(true);
  };

  const cancelEditingParticipants = () => {
    setEditingParticipants(false);
  };

  const saveNewParticipants = () => {
    if (newParticipants && course) {
      patchCourse({ max_participants: newParticipants }, course.id).then(() => {
        setEditingParticipants(false);
      });
    }
  };

  /**
   * Edit days
   */
  const editDays = () => {
    setNewDays(course?.days);
    setEditingDays(true);
  };

  const cancelEditingDays = () => {
    setEditingDays(false);
  };

  const saveNewDays = () => {
    if (newDays && course) {
      patchCourse({ days: newDays }, course.id).then(() => {
        setEditingDays(false);
      });
    }
  };

  /**
   * Edit prerequisites
   */
  const editPrerequisites = () => {
    setNewPrerequisites(course?.prerequisites);
    setEditingPrerequisites(true);
  };

  const cancelEditingPrerequisites = () => {
    setEditingPrerequisites(false);
  };

  const saveNewPrerequisites = () => {
    if (newPrerequisites && course) {
      patchCourse({ prerequisites: newPrerequisites }, course.id).then(() => {
        setEditingPrerequisites(false);
      });
    }
  };

  /**
   * Edit price
   */

  const editPrice = () => {
    setNewPrice(course?.price);
    setEditingPrice(true);
  };

  const cancelEditingPrice = () => {
    setEditingPrice(false);
  };

  const saveNewPrice = () => {
    if (newPrice && course) {
      patchCourse({ price: newPrice }, course.id).then(() => {
        setEditingPrice(false);
      });
    }
  };

  /**
   * Edit ingress
   */
  const editIngress = () => {
    setNewIngress(course?.ingress);
    setEditingIngress(true);
  };

  const cancelEditingIngress = () => {
    setEditingIngress(false);
  };

  const saveNewIngress = () => {
    if (newIngress && course) {
      patchCourse({ ingress: newIngress }, course.id).then(() => {
        setEditingIngress(false);
      });
    }
  };

  /**
   * Edit Content blocks
   */

  const editContentBlock = (index: number, content: ContentBlock) => {
    setNewContent(content);
    setEditingContentAtIndex(index);
  };

  const cancelEditingContentBlock = () => {
    setEditingContentAtIndex(null);
    setNewContent(undefined);
  };

  const cancelInsertingContentBlock = () => {
    setInsertingContentAtIndex(null);
    setNewContent(undefined);
  };

  const saveContentBlock = () => {
    if (course && newContent) {
      if (editingContentAtIndex !== null) {
        updateCourseContent(course.id, editingContentAtIndex, newContent).then(
          () => {
            setEditingContentAtIndex(null);
            setNewContent(undefined);
          }
        );
      } else if (insertingContentAtIndex !== null) {
        insertCourseContent(
          course.id,
          insertingContentAtIndex,
          newContent
        ).then(() => {
          setInsertingContentAtIndex(null);
          setNewContent(undefined);
        });
      }
    }
  };

  const deleteContentBlock = (index: number) => {
    if (course) {
      removeCourseContent(course.id, index);
    }
  };

  const moveContentBlockOneStep = (index: number, direction: "up" | "down") => {
    if (course) {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= course.content.length) {
        return;
      }
      moveCourseContent(course.id, index, newIndex);
    }
  };

  const bookablesForCourse = bookables.filter(
    (b) => b.course_id === course?.id
  );

  const coursesInCategory = courses.filter(
    (c) => c.category === course?.category
  );

  const navigate = useNavigate();

  const location = useLocation();
  const current_path = location.pathname;

  const sidebarItems: SidebarItemData[] | undefined =
    course !== undefined
      ? [
          {
            color: "#f8c797",
            label: "START",
            url: "/",
            back: true,
            large: true,
          },
          {
            color: "#fad9ba",
            label: course.category,
            url: `/courses/${course.category_slug}`,
          },
          ...coursesInCategory.map((c) => ({
            color:
              `/courses/${c.category_slug}/${c.slug}` === current_path
                ? "#fdecdc"
                : "#FFFFFF",
            label: c.title,
            url: `/courses/${c.category_slug}/${c.slug}`,
          })),
        ]
      : undefined;

  const quickDataCellStyle = {
    padding: "2px 20px 2px 0",
  };
  const quickDataRowStyle = {
    backgroundColor: "transparent !important",
  };

  return (
    <Page
      header="courses"
      title={course?.category}
      sidebar={sidebarItems}
      right_sidebar={
        <Stack paddingTop={2} direction={"column"}>
          {fetchingCourses ? (
            <>
              <Skeleton variant="rounded">
                <Typography variant={"body1"} fontStyle={"italic"}>
                  "En verkligt kvalitativ kurs med både teori och praktik."
                </Typography>
              </Skeleton>
              <Skeleton variant="rounded">
                <Typography variant={"body1"} fontStyle={"italic"}>
                  "Kändes som en privatlektion då man alltid fick mycket enskild
                  feedback."
                </Typography>
              </Skeleton>
              <Skeleton variant="rounded">
                <Typography variant={"body1"} fontStyle={"italic"}>
                  "Dessa tre dagar gjorde mer för mig än någon annan utbildning
                  gjort någonsin."
                </Typography>
              </Skeleton>
            </>
          ) : (
            course?.reviews.map((review) => (
              <Typography
                variant={"body1"}
                fontStyle={"italic"}
                key={review.id}
              >
                "{review.review}"
              </Typography>
            ))
          )}
        </Stack>
      }
      breadcrumbs={
        course
          ? [
              {
                title: "Start",
                url: "/",
              },
              {
                title: course.category,
                url: `/courses/${course.category_slug}`,
              },
              {
                title: course.title,
                url: `/courses/${course.category_slug}/${course.slug}`,
              },
            ]
          : []
      }
    >
      {course ? (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"baseline"}
          >
            <Typography
              variant={"h2"}
              style={{
                flexShrink: 1,
              }}
            >
              {editingTitle ? (
                <TextField
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  fullWidth
                  size="small"
                />
              ) : (
                course.title
              )}
            </Typography>
            {edit && (
              <EditButton
                editing={editingTitle}
                changed={newTitle !== course.title}
                onSave={saveNewTitle}
                onEdit={editTitle}
                onCancel={cancelEditingTitle}
              />
            )}
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Table sx={{ width: "auto", tableLayout: "auto" }}>
              <TableBody>
                <TableRow sx={quickDataRowStyle}>
                  <TableCell sx={quickDataCellStyle}>
                    <Typography variant="caption" fontWeight={"bold"}>
                      Antal deltagare
                    </Typography>
                  </TableCell>
                  <TableCell sx={quickDataCellStyle}>
                    <Stack direction={"row"} alignItems={"center"}>
                      {editingParticipants ? (
                        <TextField
                          value={newParticipants}
                          onChange={(e) => setNewParticipants(e.target.value)}
                          fullWidth
                          size="small"
                        />
                      ) : (
                        <Typography variant="caption">
                          {course.max_participants}
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>
                  {edit && (
                    <TableCell sx={quickDataCellStyle}>
                      <EditButton
                        editing={editingParticipants}
                        changed={newParticipants !== course.max_participants}
                        onSave={saveNewParticipants}
                        onEdit={editParticipants}
                        onCancel={cancelEditingParticipants}
                        small
                      />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow sx={quickDataRowStyle}>
                  <TableCell sx={quickDataCellStyle}>
                    <Typography variant="caption" fontWeight={"bold"}>
                      Förkunskaper
                    </Typography>
                  </TableCell>
                  <TableCell sx={quickDataCellStyle}>
                    {editingPrerequisites ? (
                      <TextField
                        value={newPrerequisites}
                        onChange={(e) => setNewPrerequisites(e.target.value)}
                        fullWidth
                        size="small"
                      />
                    ) : (
                      <Typography variant="caption">
                        {course.prerequisites}
                      </Typography>
                    )}
                  </TableCell>
                  {edit && (
                    <TableCell sx={quickDataCellStyle}>
                      <EditButton
                        editing={editingPrerequisites}
                        changed={newPrerequisites !== course.prerequisites}
                        onSave={saveNewPrerequisites}
                        onEdit={editPrerequisites}
                        onCancel={cancelEditingPrerequisites}
                        small
                      />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow sx={quickDataRowStyle}>
                  <TableCell sx={quickDataCellStyle}>
                    <Typography variant="caption" fontWeight={"bold"}>
                      Antal dagar
                    </Typography>
                  </TableCell>
                  <TableCell sx={quickDataCellStyle}>
                    {editingDays ? (
                      <TextField
                        value={newDays}
                        onChange={(e) => setNewDays(e.target.value)}
                        fullWidth
                        size="small"
                      />
                    ) : (
                      <Typography variant="caption">{course.days}</Typography>
                    )}
                  </TableCell>
                  {edit && (
                    <TableCell sx={quickDataCellStyle}>
                      <EditButton
                        editing={editingDays}
                        changed={newDays !== course.days}
                        onSave={saveNewDays}
                        onEdit={editDays}
                        onCancel={cancelEditingDays}
                        small
                      />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow sx={quickDataRowStyle}>
                  <TableCell colSpan={2} sx={quickDataCellStyle}>
                    {editingPrice ? (
                      <TextField
                        value={newPrice}
                        onChange={(e) => setNewPrice(+e.target.value)}
                        fullWidth
                        size="small"
                      />
                    ) : (
                      <Typography variant="caption">
                        {course.price.toLocaleString("sv-SE", {
                          style: "currency",
                          currency: "SEK",
                        })}
                        {" ex. moms"}
                      </Typography>
                    )}
                  </TableCell>
                  {edit && (
                    <TableCell sx={quickDataCellStyle}>
                      <EditButton
                        editing={editingPrice}
                        changed={newPrice !== course.price}
                        onSave={saveNewPrice}
                        onEdit={editPrice}
                        onCancel={cancelEditingPrice}
                        small
                      />
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
            {course.top_image && (
              <img src={course.top_image} alt={course.title} />
            )}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            {editingIngress ? (
              <TextField
                value={newIngress}
                onChange={(e) => setNewIngress(e.target.value)}
                fullWidth
                size="small"
                multiline
              />
            ) : (
              <Ingress>{course.ingress}</Ingress>
            )}
            {edit && (
              <EditButton
                editing={editingIngress}
                changed={newIngress !== course.ingress}
                onSave={saveNewIngress}
                onEdit={editIngress}
                onCancel={cancelEditingIngress}
              />
            )}
          </Stack>
          {course.center_image && (
            <img
              src={course.center_image}
              alt={course.title}
              style={{ width: "100%" }}
            />
          )}
          {course.content &&
            isFunction(course.content.map) &&
            course.content.map((content, index) => (
              <React.Fragment key={`content-section-${index}`}>
                {edit &&
                  (insertingContentAtIndex === index ? (
                    <Section
                      title={newContent?.title || ""}
                      expandable={newContent?.expandable || false}
                      editable={true}
                      onEdit={() => {}}
                      onCancel={cancelInsertingContentBlock}
                      onChange={(title, content, expandable) => {
                        setNewContent({ title, content, expandable });
                      }}
                      onSave={saveContentBlock}
                      changed={
                        newContent?.content !== "" || newContent.title !== ""
                      }
                      text={newContent?.content || ""}
                      editing={insertingContentAtIndex === index}
                      onDelete={() => deleteContentBlock(index)}
                      onMoveOneStep={(direction) =>
                        setInsertingContentAtIndex(
                          insertingContentAtIndex +
                            (direction === "up" ? -1 : 1)
                        )
                      }
                      canMoveUp={insertingContentAtIndex > 0}
                      canMoveDown={
                        insertingContentAtIndex < course.content.length
                      }
                    >
                      <MuiMarkdown>{content.content}</MuiMarkdown>
                    </Section>
                  ) : (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-around"}
                      p={2}
                    >
                      <AddCircleOutlineRounded
                        key={`content-section-${index}`}
                        onClick={
                          insertingContentAtIndex || editingContentAtIndex
                            ? undefined
                            : () => {
                                setNewContent({
                                  title: "",
                                  content: "",
                                  expandable: false,
                                });
                                setInsertingContentAtIndex(index);
                              }
                        }
                        style={{
                          cursor:
                            insertingContentAtIndex || editingContentAtIndex
                              ? "not-allowed"
                              : "pointer",
                        }}
                        color={
                          insertingContentAtIndex || editingContentAtIndex
                            ? "disabled"
                            : "action"
                        }
                      />
                    </Stack>
                  ))}
                <Section
                  title={
                    editingContentAtIndex === index
                      ? newContent?.title || ""
                      : content.title
                  }
                  expandable={
                    editingContentAtIndex === index
                      ? newContent?.expandable
                      : content.expandable
                  }
                  editable={edit ? true : undefined}
                  editingDisabled={
                    insertingContentAtIndex ||
                    (editingContentAtIndex && editingContentAtIndex !== index)
                      ? true
                      : false
                  }
                  onEdit={() => {
                    editContentBlock(index, content);
                  }}
                  onCancel={cancelEditingContentBlock}
                  onChange={(title, content, expandable) => {
                    setNewContent({ title, content, expandable });
                  }}
                  onSave={saveContentBlock}
                  changed={
                    newContent?.content !== content.content ||
                    newContent.title !== content.title ||
                    newContent.expandable !== content.expandable
                  }
                  text={newContent?.content || ""}
                  editing={editingContentAtIndex === index}
                  onDelete={() => deleteContentBlock(index)}
                  onMoveOneStep={(direction) =>
                    moveContentBlockOneStep(index, direction)
                  }
                  canMoveUp={
                    !insertingContentAtIndex &&
                    !editingContentAtIndex &&
                    index > 0
                  }
                  canMoveDown={
                    !insertingContentAtIndex &&
                    !editingContentAtIndex &&
                    index < course.content.length - 1
                  }
                >
                  <MuiMarkdown>{content.content}</MuiMarkdown>
                </Section>
              </React.Fragment>
            ))}

          {edit &&
            (insertingContentAtIndex === course.content.length ? (
              <Section
                title={newContent?.title || ""}
                expandable={newContent?.expandable || false}
                editable={true}
                onEdit={() => {
                  setInsertingContentAtIndex(course.content.length);
                }}
                onCancel={cancelInsertingContentBlock}
                onChange={(title, content, expandable) => {
                  setNewContent({ title, content, expandable });
                }}
                onSave={saveContentBlock}
                changed={
                  newContent?.content !== "" ||
                  newContent?.title !== "" ||
                  newContent?.expandable !== false
                }
                text={newContent?.content || ""}
                editing={true}
                onDelete={() => {}}
                deletionDisabled
                editingDisabled
                onMoveOneStep={() =>
                  setInsertingContentAtIndex(insertingContentAtIndex - 1)
                }
                canMoveUp={insertingContentAtIndex > 0}
              >
                <MuiMarkdown>{newContent?.content}</MuiMarkdown>
              </Section>
            ) : (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-around"}
                p={2}
              >
                <AddCircleOutlineRounded
                  onClick={
                    insertingContentAtIndex !== null || editingContentAtIndex
                      ? undefined
                      : () => {
                          setNewContent({
                            title: "",
                            content: "",
                            expandable: false,
                          });
                          setInsertingContentAtIndex(course.content.length);
                        }
                  }
                  color={
                    insertingContentAtIndex !== null || editingContentAtIndex
                      ? "disabled"
                      : "action"
                  }
                  style={{
                    cursor:
                      insertingContentAtIndex !== null || editingContentAtIndex
                        ? "not-allowed"
                        : "pointer",
                  }}
                />
              </Stack>
            ))}

          <Table sx={{ marginTop: "85px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Stad</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {bookablesForCourse
                .reduce((acc, bookable) => {
                  const venue = acc.find(
                    (v) => v.venue_id === bookable.venue_id
                  );
                  if (venue) {
                    venue.bookables.push(bookable);
                  } else {
                    acc.push({
                      venue_id: bookable.venue_id,
                      bookables: [bookable],
                    });
                  }
                  return acc;
                }, [] as { venue_id: number; bookables: Bookable[] }[])
                .map((group, groupIndex) => (
                  <React.Fragment key={group.venue_id}>
                    {group.bookables.map((bookable, bookableIndex) => {
                      const startDate = new Date(bookable.start_date);
                      const endDate = new Date(bookable.end_date);
                      const formattedDate = `${startDate.getDate()} - ${endDate.getDate()} ${startDate.toLocaleDateString(
                        "sv-SE",
                        { month: wide ? "long" : "short" }
                      )} ${startDate.getFullYear()}`;

                      return (
                        <TableRow key={bookable.id}>
                          <TableCell>
                            {bookableIndex === 0
                              ? bookable.venue?.name ?? <Skeleton />
                              : ""}
                          </TableCell>
                          <TableCell>{formattedDate}</TableCell>
                          <TableCell align="right">
                            <CustomButton
                              onClick={() => {
                                navigate(
                                  `/courses/${course.category_slug}/${course.slug}/${bookable.id}`
                                );
                              }}
                            >
                              Boka
                            </CustomButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <>
          <Skeleton variant="rounded">
            <Typography variant={"h2"}>Praktisk retorik</Typography>
          </Skeleton>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Antal deltagare
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded">
                <Typography variant="body2">4-6</Typography>
              </Skeleton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Förkunskaper
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded">
                <Typography variant="body2">Inga</Typography>
              </Skeleton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Antal dagar
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded">
                <Typography variant="body2">3 heldagar</Typography>
              </Skeleton>
            </Grid>
          </Grid>
          <Skeleton variant="rounded">
            <Ingress>
              Vill du också slipa på konsten att presentera, informera och
              argumentera? Då rekommenderar vi vår välbeprövade utbildning
              ”Praktiskt retorik” som vi sedan 2006 genomfört för mer än tusen
              nöjda kursdeltagare.
            </Ingress>
          </Skeleton>
          <SectionHeader title={"Om kursen"} />
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              Varför är kursen så omtyckt? Enligt kursdeltagarna handlar det
              främst om tre faktorer:
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              En liten grupp som ger dig mer plats. För att du ska få möjlighet
              att öva, få personlig respons, ställa frågor, diskutera och
              reflektera så mycket som möjligt är vi max sex deltagare på
              kursen. Det här är en av de absolut viktigaste aspekterna för våra
              kursdeltagare.
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              Utbildningen kopplas till skarpa situationer. Teori i alla ära,
              men under kursen kopplas alla övningar till situationer som är
              relevanta i just ditt arbete. Det här gör också att du behöver
              lägga ner lite tid på egen hand mellan kursdagarna för att
              förbereda övningar och verkligen ”gnugga in” det du lärt dig under
              kursen. Utdelningen blir så mycket större då.
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              Vi tar dig från förberedelse till framträdande. Under trygga
              former lotsar vi dig genom allt du behöver tänka på, från
              förberedelser till framförande, för att lyckas föra fram ditt
              budskap och verkligen övertyga i olika situationer. Genomgångar
              varvas med flera övningar så att du får reflektera, testa, få
              feedback – och testa igen. 
            </Typography>
          </Skeleton>
          <SectionHeader title={"Innehåll"} />
          <List sx={{ listStyleType: "disc" }}>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Att skapa trovärdighet och intresse för din person och ditt ämne
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Vad lyssnarna minns och hur du kan arbeta för att de ska minnas
                det du vill
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Vad man bör tänka på i inledningen för att fånga och hålla kvar
                lyssnarnas uppmärksamhet
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Hur du kan engagera och inspirera lyssnarna
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Att arbeta med exempel och berättelser
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Hur du gör snabba och genomtänkta förberedelser för att nå fram
                till dina olika målgrupper
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Nervositet – hur du kan mildra den
              </ListItem>
            </Skeleton>
          </List>
          <SectionHeader title="Mer om utbildningen" />
          <SectionHeader title="Vanliga frågor" />
        </>
      )}
    </Page>
  );
};

export default CourseDetail;
