import { Page } from "@comp/Page";
import { useStore } from "@feat/common/store/store";
import useAuth from "@feat/user/authService";
import {
  AccountCircleSharp,
  Dashboard,
  List,
  Logout,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Stack,
} from "@mui/material";
import { Route, Routes } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import ListBookingsRoute from "./routes/ListBookingsRoute";
import ManageUsersRoute from "./routes/ManageUsersRoute";
import OverviewRoute from "./routes/OverviewRoute";

const AdminMenuItem: React.FC<{
  to?: string;
  onClick?: React.MouseEventHandler<HTMLLIElement> | undefined;
  label: string;
  icon?: React.ReactNode;
  selected?: boolean;
}> = ({ to, onClick, label, icon, selected }) => {
  let itemProps: MenuItemProps & { to?: string } = {
    dense: true,
    selected,
    onClick,
  };
  if (to) {
    itemProps.component = Link;
    itemProps.to = to;
  }

  return (
    <MenuItem component={Link} {...itemProps}>
      <Stack direction="row" alignItems="center">
        <ListItemIcon>{icon ? icon : <Dashboard />}</ListItemIcon>
        <ListItemText primary={label} />
      </Stack>
    </MenuItem>
  );
};

const AdminRoute: React.FC = () => {
  const { user } = useStore();
  const { logoutHandler } = useAuth();

  const onClickLogout = () => {
    logoutHandler();
  };

  if (user) {
    return (
      <Page
        title={user}
        sidebar={[
          {
            label: "Översikt",
            url: "/admin",
            color: "#fff",
          },
          {
            label: "Användare",
            url: "/admin/users",
            color: "#fff",
          },
          {
            label: "Bokningar",
            url: "/admin/bookings",
            color: "#fff",
          },
          {
            label: "Inställningar",
            url: "/admin/settings",
            color: "#fff",
          },
          {
            label: "Logga ut",
            onClick: onClickLogout,
            color: "#fff",
          },
        ]}
      >
        <Box flex={1} minWidth={320}>
          <Routes>
            <Route path="/" element={<OverviewRoute />} />
            <Route path="bookings" element={<ListBookingsRoute />} />
            <Route path="users" element={<ManageUsersRoute />} />
            <Route path="*" element={<p>404</p>} />
          </Routes>
        </Box>
      </Page>
    );
  }

  return (
    <Page title="Logga in">
      <Stack direction={"row"} justifyContent={"center"}>
        <LoginForm />
      </Stack>
    </Page>
  );
};

export default AdminRoute;
