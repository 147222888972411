import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import EditButton, { EditButtonProps } from "./EditButton";
import {
  ArrowDropDown,
  ArrowDropUp,
  AspectRatio,
  SettingsOverscan,
} from "@mui/icons-material";

export type SectionHeaderAction = {
  label: React.ReactNode | string;
  onClick: () => void;
};

export const SectionHeader: React.FC<
  {
    title: string;
    rightAction?: SectionHeaderAction;
  } & (
    | ({
        editable: true;
        onChangeTitle: (title: string) => void;
        onChangeExpandable: (expandable: boolean) => void;
        onMoveOneStep?: (direction: "up" | "down") => void;
        canMoveUp?: boolean;
        canMoveDown?: boolean;
        expandable: boolean;
      } & EditButtonProps)
    | {
        editable?: boolean;
        onChangeTitle?: never;
        onChangeExpandable?: never;
        onMoveOneStep?: never;
        canMoveUp?: boolean;
        canMoveDown?: boolean;
        expandable?: boolean;
      }
  )
> = ({
  title,
  rightAction,
  editable = false,
  onChangeTitle,
  onChangeExpandable,
  onMoveOneStep,
  canMoveUp = false,
  canMoveDown = false,
  expandable = false,
  ...editButtonProps
}) => {
  const editing = "editing" in editButtonProps && editButtonProps.editing;
  return (
    <h3
      style={{
        fontSize: "16px",
        fontFamily: "Open Sans",
        color: "#222222",
        fontWeight: "normal",
        padding: "12px 8px 4px 8px",
        borderBottom: "1px solid #f9d0a9",
        margin: "0",
      }}
      onClick={rightAction?.onClick}
    >
      <Stack
        justifyContent={"space-between"}
        direction={"row"}
        alignItems={"center"}
        gap={1}
      >
        {editable && "editing" in editButtonProps && editButtonProps.editing ? (
          <>
            <TextField
              id="standard-basic"
              label="Blocktitel (valfritt)"
              value={title}
              onChange={(e) => {
                console.log("onChange");
                onChangeTitle?.(e.target.value);
              }}
              fullWidth
              autoFocus
              size="small"
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={expandable}
                    onChange={() => {
                      onChangeExpandable?.(!expandable);
                    }}
                    size="small"
                  />
                }
                label={<SettingsOverscan titleAccess="Expanderbar" />}
                slotProps={{
                  typography: {
                    style: {
                      marginTop: "4px",
                    },
                  },
                }}
              />
            </FormGroup>
            {onMoveOneStep && (
              <Stack direction="column" gap={0} flexShrink={1}>
                <ArrowDropUp
                  onClick={() => onMoveOneStep("up")}
                  style={{
                    cursor: canMoveUp ? "pointer" : "not-allowed",
                    width: "16px",
                    height: "10px",
                  }}
                  viewBox="6 8 12 8"
                  width="16"
                  height="10"
                  fontSize="small"
                  color={canMoveUp ? "action" : "disabled"}
                />
                <ArrowDropDown
                  onClick={() => onMoveOneStep("down")}
                  style={{
                    cursor: canMoveDown ? "pointer" : "not-allowed",
                    width: "16px",
                    height: "10px",
                  }}
                  viewBox="6 8 12 8"
                  width="16"
                  height="10"
                  fontSize="small"
                  color={canMoveDown ? "action" : "disabled"}
                />
              </Stack>
            )}
          </>
        ) : (
          <Box flexGrow={1}>{title}</Box>
        )}
        {rightAction && !editing && (
          <Box
            style={{
              cursor: "pointer",
              color: "#f9d0a9",
            }}
            onClick={rightAction.onClick}
          >
            {rightAction.label}
          </Box>
        )}
        {editable && "onEdit" in editButtonProps && (
          <EditButton {...editButtonProps} />
        )}
      </Stack>
    </h3>
  );
};
