import { Box, Stack, useMediaQuery } from "@mui/material";
import { ReactComponent as CourseHeaderLeft } from "@assets/course_header_left.svg";
import { ReactComponent as CourseHeaderRight } from "@assets/course_header_right.svg";
import { ReactComponent as CourseHeaderTitleDecor } from "@assets/course_header_title_decor.svg";
import styled from "@emotion/styled";
import React from "react";
import theme from "src/theme";

export type PageHeaderConfigKey = keyof typeof headers;

type svg = React.FC<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
> | null;

const headers: {
  [key: string]: {
    left: svg;
    right: svg;
    title_decor: svg;
    background: string;
  };
} = {
  courses: {
    left: CourseHeaderLeft,
    right: CourseHeaderRight,
    title_decor: CourseHeaderTitleDecor,
    background: "#F08A27CD",
  },
  blank: {
    left: null,
    right: null,
    title_decor: null,
    background: "#F08A27CD",
  },
};

const PageTitle = styled.h2`
  font-family: "chaparral-pro";
  font-style: "semi-bold";
  font-size: 38px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
`;

const PageHeader: React.FC<{
  title?: string;
  config: keyof typeof headers;
  height: number;
}> = ({ title, config, height }) => {
  const wide = useMediaQuery(theme.breakpoints.up("md"));
  const { left, right, title_decor, background } = headers[config];
  const Left = left ?? React.Fragment;
  const Right = right ?? React.Fragment;
  const TitleDecor = title_decor ?? React.Fragment;
  return (
    <Box
      position={"relative"}
      height={height}
      overflow={"hidden"}
      width={"100%"}
    >
      <Box
        display="flex"
        sx={{ background: background }}
        position={"absolute"}
        justifyContent="space-between"
        alignContent={"center"}
        width={"100%"}
      >
        <Left height={height} width={undefined} />
        <Right height={height} width={undefined} />
      </Box>
      {title && (
        <Stack
          alignItems="center"
          direction={"row"}
          gap={wide ? 2 : 1}
          position={"absolute"}
          left="50%"
          top="50%"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <PageTitle style={wide ? {} : { fontSize: "24px" }}>
            {title.replace(" ", "\u00a0")}
          </PageTitle>
          <TitleDecor height={height * 0.57} width={undefined} />
        </Stack>
      )}
    </Box>
  );
};

export default PageHeader;
