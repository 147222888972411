import { useStore } from "@feat/common/store/store";
import apiClient from "@api/client";

const loginEndpoint = "auth/login";
const logoutEndpoint = "auth/logout";
const refreshEndpoint = "auth/refresh";

export const refreshTokens = async (refreshToken: string) => {
  const response = await apiClient.post(refreshEndpoint, {
    refreshToken,
  });
  return response.data;
};

const useAuth = () => {
  const { login, logout, accessToken, refreshToken } = useStore((state) => ({
    login: state.login,
    logout: state.logout,
    accessToken: state.accessToken,
    refreshToken: state.refreshToken,
  }));

  const loginHandler = async (
    username: string,
    password: string,
    remember: boolean,
  ) => {
    const response = await apiClient.post(loginEndpoint, {
      username,
      password,
    });
    const { access_token: jwtToken, refresh_token: refreshToken } =
      response.data;
    login(username, jwtToken, refreshToken);

    if (remember) {
      localStorage.setItem(
        "credentials",
        JSON.stringify({ username, password }),
      );
    } else {
      localStorage.removeItem("credentials");
    }

    return {
      jwtToken,
      refreshToken,
    };
  };

  const logoutHandler = async () => {
    await apiClient.post(logoutEndpoint).catch((error) => {
      // 401 is a successful failure here;
      // the token is already invalid
      if (error.response?.status !== 401) {
        throw error;
      }
    });
    logout();
    localStorage.removeItem("credentials");
  };

  const createUserHandler = async (username: string, password: string) => {
    return apiClient.post("users", {
      username,
      password,
    });
  };

  return {
    loginHandler,
    logoutHandler,
    createUserHandler,
    accessToken,
    refreshToken,
  };
};

export default useAuth;
