import styled from "@emotion/styled";
import AdminRoute from "@feat/admin/AdminRoute";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { NoMatch } from "./features/404/NoMatchRoute";
import { Footer } from "./features/common/components/Footer";
import { Header } from "./features/common/components/Header";
import HomeRoute from "./features/home/HomeRoute";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import { Page } from "@comp/Page";
import { DataProvider } from "@api/DataProvider";
import CourseCategory from "@course/CourseCategory";
import CourseDetail from "@course/CourseDetail";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { Booking } from "@feat/booking/BookingRoute";
import { useStore } from "@store/store";
import React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const pages = [
  {
    name: "Kontakt",
    url: "/contact",
    element: (
      <Page title="Kontakt">
        <p>Kommer snart</p>
      </Page>
    ),
  },
  {
    name: "Vi som utbildar",
    url: "/teachers",
    element: (
      <Page title="Vi som utbildar">
        <p>Kommer snart</p>
      </Page>
    ),
  },
  {
    name: "Tidigare uppdrag",
    url: "/references",
    element: (
      <Page title="Tidigare uppdrag">
        <p>Kommer snart</p>
      </Page>
    ),
  },
  {
    name: "Orter",
    url: "/locations",
    element: (
      <Page title="Orter">
        <p>Kommer snart</p>
      </Page>
    ),
  },
];

function App() {
  const { user } = useStore();
  const stickyHeader = useMediaQuery(theme.breakpoints.up("sm"));
  const headerPages = [
    ...(user ? [{ name: "Admin", url: "/admin" }] : []),
    ...pages,
  ];

  const [shadowOpacity, setShadowOpacity] = React.useState(0);

  React.useEffect(() => {
    if (stickyHeader) return;

    const handleScroll = () => {
      setShadowOpacity(Math.min(window.scrollY / 200, 0.2));
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stickyHeader]);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DataProvider>
          <Container>
            {stickyHeader ? (
              <Header pages={headerPages} />
            ) : (
              <>
                <div style={{ opacity: 0 }}>
                  <Header pages={headerPages} />
                </div>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 1000,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, " + shadowOpacity + ")",
                  }}
                >
                  <Header pages={headerPages} />
                </div>
              </>
            )}
            <Routes>
              <Route path="/" element={<HomeRoute />} />
              {pages.map((page) => (
                <Route key={page.url} path={page.url} element={page.element} />
              ))}
              <Route
                path="/courses/:category_slug"
                element={<CourseCategory />}
              />
              <Route
                path="/courses/:category_slug/:course_slug"
                element={<CourseDetail />}
              />
              <Route
                path="/courses/:category_slug/:course_slug/:bookable_id"
                element={<Booking />}
              />
              <Route path="/booking" element={<Booking />} />
              <Route path="/admin/*" element={<AdminRoute />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
            <Footer />
          </Container>
        </DataProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
