import { useStore } from "@feat/common/store/store";
import apiClient from "@api/client";
import { useCallback } from "react";

const usersEndpoint = "users";

const useUser = () => {
  const {
    users,
    fetchingUsers,
    setFetchingUsers,
    setUsers,
    setUser,
    removeUser,
  } = useStore((state) => ({
    users: state.users,
    fetchingUsers: state.fetchingUsers,
    setFetchingUsers: state.setFetchingUsers,
    setUsers: state.setUsers,
    setUser: state.setUser,
    removeUser: state.removeUser,
  }));

  const fetchUsers = useCallback(async () => {
    setFetchingUsers(true);
    apiClient
      .get(usersEndpoint)
      .then((response) => {
        setUsers(response.data);
      })
      .finally(() => {
        setFetchingUsers(false);
      });
  }, [setUsers, setFetchingUsers]);

  const createUser = useCallback(
    async (username: string, password: string, emailNotifications: boolean) => {
      const response = await apiClient.post(usersEndpoint, {
        username,
        password_hash: password,
        email_notifications: emailNotifications,
      });
      setUser(response.data);
    },
    [setUser],
  );

  const updateUser = useCallback(
    async (
      id: number,
      username: string,
      password: string,
      emailNotifications: boolean,
    ) => {
      const response = await apiClient.put(`${usersEndpoint}/${id}`, {
        username,
        password_hash: password,
        email_notifications: emailNotifications,
      });
      setUser(response.data);
    },
    [setUser],
  );

  const deleteUser = useCallback(
    async (id: number) => {
      apiClient.delete(`${usersEndpoint}/${id}`).then(() => {
        removeUser(id);
      });
    },
    [removeUser],
  );

  return {
    users,
    fetchUsers,
    fetchingUsers,
    createUser,
    updateUser,
    deleteUser,
  };
};

export default useUser;
