import { useState } from "react";
import { SectionHeader } from "./SectionHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import { Box, TextField } from "@mui/material";
import { EditButtonProps } from "./EditButton";

const Section: React.FC<
  {
    title: string;
    expandable?: boolean;
    children: React.ReactNode;
  } & (
    | ({
        editable?: true;
        text: string;
        onChange: (title: string, content: string, expandable: boolean) => void;
        onMoveOneStep?: (direction: "up" | "down") => void;
        canMoveUp?: boolean;
        canMoveDown?: boolean;
      } & EditButtonProps)
    | {
        editable?: boolean;
        onChange?: never;
        text?: never;
        onMoveOneStep?: never;
        canMoveUp?: boolean;
        canMoveDown?: boolean;
      }
  )
> = ({
  title,
  children,
  expandable = false,
  editable = false,
  onChange,
  onMoveOneStep,
  canMoveUp = false,
  canMoveDown = false,
  text,
  ...editButtonProps
}) => {
  const [expanded, setExpanded] = useState(!expandable);

  const handleEditTitle = (newTitle: string) => {
    console.log("handleEditTitle", newTitle);
    if (editable && onChange) {
      console.log("handleEditTitle, firing", newTitle);
      onChange(newTitle, text, expandable);
    } else {
      console.log("handleEditTitle, not firing", newTitle);
    }
  };

  const handleEditContent = (newContent: string) => {
    console.log("handleEditContent", newContent);
    if (editable && onChange) {
      console.log("handleEditContent, firing", newContent);
      onChange(title, newContent, expandable);
    } else {
      console.log("handleEditContent, not firing", newContent);
    }
  };

  const handleEditExpandable = (newExpandable: boolean) => {
    if (editable && onChange) {
      onChange(title, text, newExpandable);
    }
  };

  const editing =
    editable && "editing" in editButtonProps && editButtonProps.editing;

  return (
    <Box style={editing ? { border: "1px dashed #a0a0a0" } : {}}>
      <SectionHeader
        title={title}
        rightAction={
          (expandable && {
            label: (
              <Fade in={true} timeout={300}>
                {expanded ? (
                  <CloseIcon color="action" />
                ) : (
                  <ExpandMoreIcon color="action" />
                )}
              </Fade>
            ),
            onClick: () => setExpanded(!expanded),
          }) ||
          undefined
        }
        {...(editable && "onEdit" in editButtonProps
          ? {
              editable: true,
              onChangeTitle: handleEditTitle,
              expandable,
              onChangeExpandable: handleEditExpandable,
              onMoveOneStep,
              canMoveUp,
              canMoveDown,
              ...editButtonProps,
            }
          : { editable: false })}
      />
      {editing ? (
        <Box p={1}>
          <TextField
            value={text}
            label="Markdown-innehåll"
            onChange={(e) => handleEditContent(e.target.value)}
            fullWidth
            multiline
            autoFocus
          />
        </Box>
      ) : (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box marginBottom="2rem">{children}</Box>
        </Collapse>
      )}
    </Box>
  );
};

export default Section;
