import useStatus from "@feat/status/statusService";
import { Cancel, Check, Downloading } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

const iconSizeStyle = {
  fontSize: 20,
  width: 20,
  height: 20,
};

const Health: React.FC = () => {
  const { version, previousVersion, healthy, gettingStatus } = useStatus();

  const onClickReload = () => {
    window.location.reload();
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box sx={iconSizeStyle}>
        {gettingStatus ? (
          <Downloading sx={iconSizeStyle} />
        ) : healthy ? (
          <Check sx={iconSizeStyle} />
        ) : (
          <Cancel sx={iconSizeStyle} />
        )}
      </Box>
      {previousVersion !== version ? (
        <Box onClick={onClickReload}>
          Byt <b>{previousVersion}</b> till <b>{version}</b>
        </Box>
      ) : (
        <Box>{previousVersion}</Box>
      )}
    </Stack>
  );
};

export default Health;
