import { StateCreator } from "zustand";
import { Bookable } from "@api/types";

export type BookableWithSlugs = Bookable & {
  slug: string;
};

export interface BookablesState {
  fetchingBookables: boolean;
  bookables: BookableWithSlugs[];
  hasFetchedBookables: boolean;
  setFetchingBookables: (fetchingBookables: boolean) => void;
  setBookables: (bookables: Bookable[]) => void;
  setBookable: (bookable: Bookable) => void;
  removeBookable: (id: number) => void;
}

const slugify = (str: string): string => str.toLowerCase().replace(/ /g, "-");

const addSlug = (bookable: Bookable): BookableWithSlugs => ({
  ...bookable,
  slug: slugify(bookable.start_date), // Assuming start_date is used for slug
});

export const bookablesSlice: StateCreator<BookablesState> = (set) => ({
  fetchingBookables: false,
  bookables: [],
  hasFetchedBookables: false,
  setFetchingBookables: (fetchingBookables) => set({ fetchingBookables }),
  setBookables: (bookables) =>
    set({
      bookables: bookables.map(addSlug),
      hasFetchedBookables: true,
    }),
  setBookable: (bookable) => {
    const bookableWithSlug = addSlug(bookable);
    set((state) => {
      const existingBookable = state.bookables.find(
        (b) => b.id === bookableWithSlug.id,
      );
      if (existingBookable) {
        return {
          bookables: state.bookables.map((b) =>
            b.id === bookableWithSlug.id ? bookableWithSlug : b,
          ),
        };
      } else {
        return {
          bookables: [...state.bookables, bookableWithSlug],
        };
      }
    });
  },
  removeBookable: (id) =>
    set((state) => ({
      bookables: state.bookables.filter((b) => b.id !== id),
    })),
});
