import { StateCreator } from "zustand";
import { ContentBlock, Course, CourseReview } from "@api/types";
import { patchCourse } from "@api/admin/course";
import {
  deleteCourseContentBlock,
  insertCourseContentBlock,
  moveCourseContentBlock,
  updateCourseContentBlock,
} from "@api/admin/course-content";

export type CourseWithReviews = Course & {
  reviews: CourseReview[];
};

export interface CoursesState {
  fetchingCourses: boolean;
  courses: CourseWithReviews[];
  hasFetchedCourses: boolean;
  setFetchingCourses: (fetchingCourses: boolean) => void;
  setCourses: (courses: CourseWithReviews[]) => void;
  setCourse: (course: CourseWithReviews) => void;
  patchCourse: (courseData: Partial<Course>, courseId: number) => Promise<void>;
  updateCourseContent: (
    courseId: number,
    index: number,
    contentBlock: ContentBlock
  ) => Promise<void>;
  insertCourseContent: (
    courseId: number,
    index: number,
    contentBlock: ContentBlock
  ) => Promise<void>;
  moveCourseContent: (
    courseId: number,
    fromIndex: number,
    toIndex: number
  ) => Promise<void>;
  removeCourseContent: (courseId: number, index: number) => Promise<void>;
  removeCourse: (id: number) => void;
}

const slugify = (str: string): string => {
  const convertSwedishCharacters = (str: string): string => {
    return str
      .replace(/å/g, "a")
      .replace(/ä/g, "a")
      .replace(/ö/g, "o")
      .replace(/Å/g, "a")
      .replace(/Ä/g, "a")
      .replace(/Ö/g, "o");
  };
  return convertSwedishCharacters(str).toLowerCase().replace(/ /g, "-");
};

const addSlug = <T extends Course | CourseWithReviews>(course: T): T => ({
  ...course,
  slug: slugify(course.title),
  categorySlug: slugify(course.category),
});

export const coursesSlice: StateCreator<CoursesState> = (set) => ({
  fetchingCourses: false,
  courses: [],
  hasFetchedCourses: false,
  setFetchingCourses: (fetchingCourses) => set({ fetchingCourses }),
  setCourses: (courses) =>
    set({
      courses: courses.map(addSlug),
      hasFetchedCourses: true,
    }),
  setCourse: (course) => {
    const courseWithSlug = addSlug(course);
    set((state) => {
      const existingCourse = state.courses.find(
        (c) => c.id === courseWithSlug.id
      );
      if (existingCourse) {
        return {
          courses: state.courses.map((c) =>
            c.id === courseWithSlug.id ? courseWithSlug : c
          ),
        };
      } else {
        return {
          courses: [...state.courses, courseWithSlug],
        };
      }
    });
  },
  patchCourse: async (courseData, courseId) => {
    return patchCourse(courseData, courseId).then((course) => {
      return set((state) => {
        return {
          courses: state.courses.map((c) =>
            c.id === course.id
              ? {
                  ...c,
                  ...course,
                }
              : c
          ),
        };
      });
    });
  },
  updateCourseContent: async (courseId, index, contentBlock) => {
    return updateCourseContentBlock(courseId, index, contentBlock).then(
      (course) => {
        return set((state) => {
          return {
            courses: state.courses.map((c) =>
              c.id === course.id
                ? {
                    ...c,
                    ...course,
                  }
                : c
            ),
          };
        });
      }
    );
  },
  insertCourseContent: async (courseId, index, contentBlock) => {
    return insertCourseContentBlock(courseId, index, contentBlock).then(
      (course) => {
        return set((state) => {
          return {
            courses: state.courses.map((c) =>
              c.id === course.id
                ? {
                    ...c,
                    ...course,
                  }
                : c
            ),
          };
        });
      }
    );
  },
  moveCourseContent: async (courseId, fromIndex, toIndex) => {
    return moveCourseContentBlock(courseId, fromIndex, toIndex).then(
      (course) => {
        return set((state) => {
          return {
            courses: state.courses.map((c) =>
              c.id === course.id
                ? {
                    ...c,
                    ...course,
                  }
                : c
            ),
          };
        });
      }
    );
  },
  removeCourseContent: async (courseId, index) => {
    return deleteCourseContentBlock(courseId, index).then((course) => {
      return set((state) => {
        console.log(
          "Existing course: ",
          state.courses.find((c) => c.id === course.id)
        );
        console.log("New course: ", course);
        return {
          courses: state.courses.map((c) =>
            c.id === course.id
              ? {
                  ...c,
                  ...course,
                }
              : c
          ),
        };
      });
    });
  },
  removeCourse: (id) => {
    set((state) => ({
      courses: state.courses.filter((c) => c.id !== id),
    }));
  },
});
