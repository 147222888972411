import { ContentBlock } from "@api/types";
import apiClient from "../client";
import { CourseWithReviews } from "@store/coursesSlice";

export const updateCourseContentBlock = async (
  courseId: number,
  index: number,
  contentBlock: ContentBlock
) => {
  const response = await apiClient.put<CourseWithReviews>(
    `/courses/${courseId}/content/${index}`,
    contentBlock
  );
  return response.data;
};

export const insertCourseContentBlock = async (
  courseId: number,
  index: number,
  contentBlock: ContentBlock
) => {
  const response = await apiClient.post<CourseWithReviews>(
    `/courses/${courseId}/content/${index}`,
    contentBlock
  );
  return response.data;
};

export const deleteCourseContentBlock = async (
  courseId: number,
  index: number
) => {
  const response = await apiClient.delete<CourseWithReviews>(
    `/courses/${courseId}/content/${index}`
  );
  return response.data;
};

export const moveCourseContentBlock = async (
  courseId: number,
  fromIndex: number,
  toIndex: number
) => {
  const response = await apiClient.put<CourseWithReviews>(
    `/courses/${courseId}/content/${fromIndex}/move`,
    { new_index: toIndex }
  );
  return response.data;
};
