import { Course } from "@api/types";
import apiClient from "../client";
import { CourseWithReviews } from "@store/coursesSlice";

export const patchCourse = async (
  courseData: Partial<Course>,
  courseId: number
) => {
  const response = await apiClient.patch<CourseWithReviews>(
    `/courses/${courseId}`,
    courseData
  );
  return response.data;
};
