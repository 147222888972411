import { ReactComponent as LogoSvg } from "@assets/logo.svg";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  ArrowDropDown,
  Edit,
  EditOff,
  Menu as Hamburger,
} from "@mui/icons-material";
import Search from "./Search";
import gray from "src/colors/gray";
import PageWidthContainer from "./PageWidthContainer";
import theme from "src/theme";
import { CourseWithReviews } from "@feat/common/store/coursesSlice";
import { useStore } from "@feat/common/store/store";
import { useState } from "react";

const CoursesMenu: React.FC<{
  categories: { name: string; courses: CourseWithReviews[] }[];
  nested?: boolean;
}> = ({ categories, nested }) => {
  const large = useMediaQuery(theme.breakpoints.up("lg"));

  const coursesMenu = (
    <Stack
      direction={nested ? "column" : "row"}
      gap={large ? 2 : 0}
      flexGrow={1}
      justifyContent={"center"}
    >
      {categories.map((category) => (
        <CoursesMenuCategory key={category.name} name={category.name}>
          {category.courses.map((course) => (
            <CoursesMenuCourse
              key={course.id}
              name={course.title}
              url={`/courses/${course.category_slug}/${course.slug}`}
            />
          ))}
        </CoursesMenuCategory>
      ))}
    </Stack>
  );

  return nested ? (
    <PopupState variant="popover" popupId="course-main-menu">
      {(popupState) => (
        <div>
          <Button
            variant="text"
            endIcon={<ArrowDropDown htmlColor={gray[900]} />}
            {...bindTrigger(popupState)}
          >
            <Box color={gray[800]}>Utbildningar</Box>
          </Button>
          <Menu
            disableScrollLock
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {coursesMenu}
          </Menu>
        </div>
      )}
    </PopupState>
  ) : (
    coursesMenu
  );
};

const CoursesMenuCategory: React.FC<{
  name: string;
  children: React.ReactNode;
}> = ({ name, children }) => {
  const large = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <PopupState variant="popover" popupId={`course-menu-${name}`}>
      {(popupState) => (
        <div>
          <Button
            variant="text"
            fullWidth
            endIcon={<ArrowDropDown htmlColor={gray[900]} />}
            style={{ justifyContent: "space-between" }}
            {...bindTrigger(popupState)}
          >
            <Box
              color={gray[800]}
              sx={{
                fontSize: large ? 14 : 12,
                fontWeight: "bold",
              }}
            >
              {name.replace(" ", "\u00A0")}
            </Box>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {children}
          </Menu>
        </div>
      )}
    </PopupState>
  );
};

const CoursesMenuCourse: React.FC<{ name: string; url: string }> = ({
  name,
  url,
}) => {
  return (
    <MenuItem color={gray[800]} component={Link} href={url}>
      {name.replace(" ", "\u00A0")}
    </MenuItem>
  );
};

interface HeaderProps {
  pages: { name: string; url: string }[];
}

export const Header = ({ pages }: HeaderProps) => {
  const { user, edit, toggleEdit } = useStore();
  const { courses, fetchingCourses } = useStore();

  const [openCategory, setOpenCategory] = useState<string | null>(null);

  const categories: { name: string; courses: CourseWithReviews[] }[] =
    courses.reduce((acc, course) => {
      const category = acc.find(
        (c: { name: string; courses: CourseWithReviews[] }) =>
          c.name === course.category
      );
      if (category) {
        category.courses.push(course);
      } else {
        acc.push({ name: course.category, courses: [course] });
      }
      return acc;
    }, [] as { name: string; courses: CourseWithReviews[] }[]);

  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const wide = useMediaQuery(theme.breakpoints.up("md"));
  const hamburger = useMediaQuery(theme.breakpoints.down("sm"));

  const logoWidth = wide ? 193 : "auto";
  const logoHeight = wide ? undefined : "90%";

  const menuItemTextStyle = {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 14,
    textDecoration: "none",
    color: "#626262",
  };

  return (
    <header
      style={{
        backgroundColor: "white",
        zIndex: 1000,
      }}
    >
      <PageWidthContainer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          width={"100%"}
          height={wide ? 120 : 80}
          paddingTop={wide ? 2 : 1}
          paddingBottom={(wide ? 2 : 1) * 0.75}
          paddingX={large ? 0 : 2}
        >
          <Box maxWidth={logoWidth} height={logoHeight}>
            <RouterLink to="/">
              <LogoSvg width={logoWidth} height={logoHeight} />
            </RouterLink>
          </Box>
          {hamburger ? (
            <PopupState variant="popover" popupId="bottom-menu">
              {(popupState) => (
                <>
                  <Stack direction="row" gap={2} justifyContent={"flex-end"}>
                    {user && (
                      <IconButton onClick={() => toggleEdit()} size="small">
                        {edit ? <EditOff /> : <Edit />}
                      </IconButton>
                    )}
                    <IconButton {...bindTrigger(popupState)}>
                      <Hamburger />
                    </IconButton>
                  </Stack>
                  <Menu
                    {...bindMenu(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem>
                      <Search />
                    </MenuItem>
                    {fetchingCourses ? (
                      <MenuItem>
                        <CircularProgress size={18} />{" "}
                        <Typography
                          style={{ ...menuItemTextStyle, marginLeft: "1rem" }}
                        >
                          Laddar kurser
                        </Typography>
                      </MenuItem>
                    ) : (
                      categories.map((category) => (
                        <MenuItem
                          style={{ width: "100%", display: "block" }}
                          key={category.name}
                        >
                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            onClick={() =>
                              setOpenCategory(
                                openCategory === category.name
                                  ? null
                                  : category.name
                              )
                            }
                          >
                            <Typography style={menuItemTextStyle}>
                              {category.name}
                            </Typography>
                            <ArrowDropDown color={"action"} />
                          </Stack>
                          <Collapse in={openCategory === category.name}>
                            <Stack
                              direction="column"
                              gap={1}
                              paddingLeft={2}
                              paddingTop={1}
                            >
                              {category.courses.map((course) => (
                                <MenuItem key={course.id}>
                                  <Link
                                    href={`/courses/${course.category_slug}/${course.slug}`}
                                    style={menuItemTextStyle}
                                  >
                                    {course.title}
                                  </Link>
                                </MenuItem>
                              ))}
                            </Stack>
                          </Collapse>
                        </MenuItem>
                      ))
                    )}
                    {pages.map((page) => (
                      <MenuItem key={page.url}>
                        <Link href={page.url} style={menuItemTextStyle}>
                          {page.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </PopupState>
          ) : (
            <>
              {fetchingCourses ? (
                <CircularProgress />
              ) : (
                courses && (
                  <CoursesMenu categories={categories} nested={!wide} />
                )
              )}
            </>
          )}
        </Stack>
      </PageWidthContainer>

      {!hamburger && (
        <div
          style={{
            backgroundColor: gray[50],
            borderTop: `1px solid ${gray[100]}`,
          }}
        >
          <PageWidthContainer>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              gap={2}
              alignItems="center"
              height={43}
            >
              {user && (
                <IconButton onClick={() => toggleEdit()} size="small">
                  {edit ? <EditOff /> : <Edit />}
                </IconButton>
              )}
              <Stack
                direction="row"
                justifyContent={"flex-end"}
                gap={2}
                alignItems="center"
                height={43}
              >
                <>
                  {pages.map((page) => (
                    <Link
                      key={page.url}
                      href={page.url}
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        fontSize: 14,
                        textDecoration: "none",
                        color: "#626262",
                      }}
                    >
                      {page.name}
                    </Link>
                  ))}
                  <Search />
                </>
              </Stack>
            </Stack>
          </PageWidthContainer>
        </div>
      )}
    </header>
  );
};
