import { Close, DeleteForeverSharp, Edit, Save } from "@mui/icons-material";
import { Stack } from "@mui/material";

export type EditButtonProps = {
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
  editingDisabled?: boolean;
  deletionDisabled?: boolean;
  changed: boolean;
  editing: boolean;
  small?: boolean;
};

const EditButton: React.FC<EditButtonProps> = ({
  onEdit,
  onCancel,
  onSave,
  onDelete = undefined,
  editingDisabled = false,
  deletionDisabled = false,
  editing,
  changed,
  small = false,
}) => {
  const cancel = () => {
    if (!changed || window.confirm("Är du säker på att du vill avbryta?")) {
      onCancel();
    }
  };

  const save = () => {
    if (window.confirm("Är du säker på att du vill spara ändringarna?")) {
      onSave();
    }
  };

  const remove = onDelete
    ? () => {
        if (window.confirm("Är du säker på att du vill radera?")) {
          onDelete?.();
        }
      }
    : undefined;

  return (
    <Stack direction={"row"}>
      {editing ? (
        <>
          <Save
            onClick={changed ? save : undefined}
            color={changed ? "action" : "disabled"}
            style={changed ? { cursor: "pointer" } : { cursor: "not-allowed" }}
          />
          <Close
            onClick={cancel}
            color={"action"}
            style={{ cursor: "pointer" }}
          />
        </>
      ) : (
        <>
          <Edit
            onClick={editingDisabled ? undefined : onEdit}
            color={editingDisabled ? "disabled" : "action"}
            style={{
              width: small ? "14px" : "24px",
              height: small ? "14px" : "24px",
              cursor: editingDisabled ? "not-allowed" : "pointer",
            }}
          />
          {remove && (
            <DeleteForeverSharp
              onClick={deletionDisabled ? undefined : remove}
              color={deletionDisabled ? "disabled" : "action"}
              style={{ cursor: deletionDisabled ? "not-allowed" : "pointer" }}
            />
          )}
        </>
      )}
    </Stack>
  );
};
export default EditButton;
