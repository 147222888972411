import { create } from "zustand";
import { authSlice, AuthState } from "./authSlice";
import { userSlice, UserState } from "./userSlice";
import { statusSlice, StatusState } from "./statusSlice";
import { coursesSlice, CoursesState } from "./coursesSlice";
import { bookablesSlice, BookablesState } from "./bookablesSlice";
import { venuesSlice, VenuesState } from "./venuesSlice";

export type StoreState = AuthState &
  UserState &
  StatusState &
  CoursesState &
  BookablesState &
  VenuesState;

export const useStore = create<StoreState>((...a) => ({
  ...authSlice(...a),
  ...userSlice(...a),
  ...statusSlice(...a),
  ...coursesSlice(...a),
  ...bookablesSlice(...a),
  ...venuesSlice(...a),
}));
